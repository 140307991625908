@import url("https://fonts.googleapis.com/css?family=Press+Start+2P");
@import url("https://fonts.googleapis.com/css?family=V2323");
.App {
	text-align: center;
	font-family: "Press Start 2P", "V2323";
	color: white;
}
* {
	box-sizing: border-box;
}
body {
	overflow: hidden;
}
.overlay {
	opacity: 0.85;
	width: 100%;
	height: 100%;
	z-index: 10;
	top: 0;
	left: 0;
	position: fixed;
}
.bg {
	z-index: -1;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background-image: url("assets/background/parallax-bg.gif");
	background-size: cover;
	background-repeat: no-repeat;
}
.account {
	background: #14141471;
	position: relative;
	top: -10px;
}
